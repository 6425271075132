import React, { useEffect, useState } from 'react';
import { obtenerDeuda } from './../api/auth'
import { useDeudas } from './../context/DeudasContext'
import { useNavigate } from 'react-router-dom'
import './../components/Loader.css';
import { Toaster, toast } from 'react-hot-toast'
import './home.css'

export const Home = () => {

    const { getDeudas, deudasCargadasAlArray, loading, errorConsultarDeuda, setErrorConsultarDeuda, setIsAuthenticated } = useDeudas();

    const navigate = useNavigate();

    const onSubmit = (event) => {
        event.preventDefault();
        const tipo = event.target[0].value;
        const numero = event.target[1].value       
        const respuesta =  getDeudas({CodigoTipoBusqueda: tipo, CodigoReferencia: numero});
    }

    useEffect( () => {
        setIsAuthenticated(false)  
        if(deudasCargadasAlArray) {
            setIsAuthenticated(true)
            navigate('/deudas')
        }
    }, [deudasCargadasAlArray]);

    useEffect( () => {
        if(errorConsultarDeuda) {
            toast.error("Error. Usuario no encontrado")
            setErrorConsultarDeuda(false)
        }
    }, [errorConsultarDeuda]);

  return (
    <div className='d-flex justify-content-center align-items-center movil'  style={{ height: '35rem'}}>
        <div className="bg-white p-5 rounded-2 text-secondary" style={{ width: '25rem', border: '1px solid #61A9ED', margin: '10px'}}>            
            <form onSubmit={onSubmit}>
                <div className='row mt-3'>
                    <div className='col-12 col-sm-4'>
                        <label className="mb-2 mx-3" style={{ fontWeight: 'bold', width: "100%", textAlign: "start"}} htmlFor="tipo">Tipo</label> 
                    </div>
                    <div className='col-12 col-sm-8'>                        
                        <select className="form-select" id="tipo" aria-label="Floating label select example">
                            {/* <option defaultValue>Seleccione una Opción</option> */}
                            <option value="DID">Documento de Indentidad</option>
                            <option value="COD">Código de Cliente</option>
                            <option value="CON">Código de Contrato</option>
                        </select>
                    </div>
                </div>

                <div className='row mt-3 mb-4'>
                    <div className='col-12 col-sm-4'>
                        <label className="mb-2 mx-3 mt-2" style={{ fontWeight: 'bold', width: "100%", textAlign: "start"}} htmlFor="numero">Número</label> 
                    </div>
                    <div className='col-12 col-sm-8'>
                        <input className="form-control" type="text" name="numero" id="numero" placeholder="Número"/>
                    </div>
                </div>

                
                {
                    loading && (
                        <div className="d-flex justify-content-center">
                            <div className="loader2"></div>
                        </div> 
                    )
                }

                <div className="btn btn-info text-white w-100 mt-4">
                    <button className='btn btn-info text-white w-100 mt-4"'>Consultar Mi Deuda</button>
                </div>
            </form>
        </div>
        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            }}
        />
    </div>
  )
}

