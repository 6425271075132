import React, {useEffect} from 'react'
import './verificado.css'
import verificadoImg from './../assets/img/verificado/verificado.png'
import { Toaster, toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useDeudas } from './../context/DeudasContext'


export const VerificarPago = () => {
  const { getDeudas, miQR, deudas, deudasCargadasAlArray, errorConsultarDeuda, setErrorConsultarDeuda } = useDeudas();

  const navigate = useNavigate();

  const handleVolverInicio = () => { 
    // setDeudas(deudasActualizadas);
    getDeudas({CodigoTipoBusqueda: "DID", CodigoReferencia: deudas.DatosFiscales.FacturaNITCI.toString()})
  }

  useEffect( () => {   
    if(deudasCargadasAlArray) navigate('/deudas')
}, [deudasCargadasAlArray]);

useEffect( () => {
  if(errorConsultarDeuda) {
      toast.error("Error al obtener las Deudas")
      setErrorConsultarDeuda(false)
  }
}, [errorConsultarDeuda]);

  return (
    <div className="d-flex justify-content-center mt-5">
      {
        miQR ? 
          <div className="row">
            <div className="d-flex justify-content-center mt-2">
              <div className="tarjeta">      
                <span className="ribbon-1"></span>
                <div className="flexbox">            
                    <img src={ verificadoImg } alt="verify" style={{ height: '12rem', width: "13rem" }} />
                </div> 
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <h6>El pago fue realizado satisfactoriamente</h6>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button className='btn btn-info text-white my-1'onClick={handleVolverInicio}>Volver al Inicio</button>
            </div>
        </div> :
        <h5>Debes generar una deuda</h5>
      }

        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            }}
        />
            
    </div>
  )
}
