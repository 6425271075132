import axios from './axios';


export const obtenerDeuda = data => axios.post(`/api/v1/cobranza/obtenerdeuda`, data); 

export const generarQR = data => axios.post(`/api/v1/users/generarQR`, data); 

export const verificarQR = data => axios.post(`/api/v1/users/verificarQR`, data); 

export const consultarPagos = (data) => axios.post(`/api/v1/users/consultarPago`, data); 

export const reporte = (data) => axios.post(`/api/v1/reporte`, data);

export const reporteAll = (data) => axios.post(`/api/v1/reporte/all`, data);

export const loginRequest = user => axios.post(`/api/v1/auth/signinbanticfintec`, user);

export const getClienteFacturaToSAPRequest = () => axios.post(`/api/v1/users/getClienteFacturaToSAP`);

export const enviarPagoNoSicronizadoSAPRequest = clientes => axios.post(`/api/v1/users/enviarPagoNoSicronizadoSAP`, {clientes});
