import React from 'react';
import { useDeudas } from './../context/DeudasContext'
import { UserRole } from './../constants/defaultValues'
import { ZONA_HORARIA_UTC_4 } from './../utils/ZonaHorariaUTC'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


const ESTADOS = {
    PENDIENTE: 1,
    PAGADO: 2,
    EXPIRADO: 3
}

const COLOR_ESTADOS = {
    PENDIENTE: /* "#F9F74C", */ "#E4FF20",
    PAGADO: "#5abd71",
    EXPIRADO: "#FF1B1B"
}

const formatoFechaHora = (fechaHora) => {
    let fecha_hora = dayjs(fechaHora).subtract(ZONA_HORARIA_UTC_4, 'hours').utc().format('DD/MM/YYYY HH:mm:ss')
    //let fecha = dayjs(fechaHora).utc().format('DD/MM/YYYY');
    //let hora = dayjs(fechaHora).subtract(ZONA_HORARIA_UTC_4, 'hours').utc().format('HH:mm:ss');

    return `${fecha_hora}`
    //return `${dayjs(fechaHora).utc().format('DD/MM/YYYY')} ${hora}`
    //return `${dayjs(fechaHora).utc().format('DD/MM/YYYY')} ${dayjs(hora).utc().format('HH:mm:ss')}`
}

const mostrarEstado = (estadoActual) => {
    if (ESTADOS.PENDIENTE == estadoActual){
        return 'Pendiente';
    }
    if (ESTADOS.EXPIRADO == estadoActual) {
        return "Expirado";
    }
    if (ESTADOS.PAGADO == estadoActual) {
        return "Pagado";
    }
}

const pintarEstado = (estadoActual) => {        
    if (ESTADOS.PENDIENTE == estadoActual){
        return {
            //background: COLOR_ESTADOS.PENDIENTE, 
            color: COLOR_ESTADOS.PENDIENTE,
            textAlign: 'center', 
            fontWeight:'bold'
        };
    }
    if (ESTADOS.EXPIRADO == estadoActual) {
        return {
            //background: COLOR_ESTADOS.EXPIRADO, 
            color: COLOR_ESTADOS.EXPIRADO, 
            textAlign: 'center', 
            fontWeight:'bold'
        };
    }
    if (ESTADOS.PAGADO == estadoActual) {
        return {
            //background: COLOR_ESTADOS.PAGADO, 
            color: COLOR_ESTADOS.PAGADO, 
            textAlign: 'center', 
            fontWeight:'bold'
        };
    }
}

const TablaReporteHistoricoExcel = ( {headers, dataBody} ) => {
    const { user } = useDeudas();

  return (
    <table className='table table-bordered' id='tabla_historico'>
        <thead>
            <tr>
                {
                    headers.map((columna, indice) => (
                        <th key={indice}> {columna} </th>
                    ))
                }
            </tr>
        </thead>

        <tbody>
            {
                dataBody.map((detalle, indice) => (
                    <tr key={indice}>
                         { user && ( user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR) ? (
                            <td> {detalle.manageqr_id} </td>
                        ) : (
                            undefined
                        )}
                        <td> { formatoFechaHora(detalle.fecha) }</td>
                        { user && ( user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR) ? (
                            <td> {detalle.factNombre} </td>
                        ) : (
                            undefined
                        )}
                        <td> {detalle.nombreLote} </td>
                        <td> Cuota {detalle.nroCuota}</td>
                        <td> {detalle.montoCuota} </td>
                        <td> {detalle.montoMulta} </td>
                        <td> {detalle.montoTotal} </td>
                        <td > 
                            <span style={pintarEstado(detalle.estado)}>
                                {mostrarEstado(detalle.estado)}
                            </span> 
                        </td>
                    </tr>
                ))
            }
        </tbody> 
    </table>
  )
}

export default TablaReporteHistoricoExcel;