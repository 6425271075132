export const UserRole = {
    ADMIN: 'ADM',
    SALE: 'SALE',
    BANK: 'BANK',
    CLIENT: 'CLIENT',
    CONTADOR: 'CONT'
};
  

export const currentUser = {
    id: 1,
    name: "URBANOR",
    role: UserRole.ADMIN,
};

export const getCurrentUser = () => {
    let user = null;
    try {
      user =
        localStorage.getItem('current_user_id') != null
          ? JSON.parse(localStorage.getItem('current_user_id'))
          : null;
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
      user = null;
    }
    return user;
};

export const setCurrentUser = (user) => {
    try {
      if (user) {
        localStorage.setItem('current_user_id', JSON.stringify(user));
      } else {
        localStorage.removeItem('current_user_id');
      }
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
    }
};