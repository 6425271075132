import { createContext, useContext, useState, useEffect } from "react";
import { 
    obtenerDeuda, 
    generarQR, 
    verificarQR, 
    consultarPagos, 
    reporte, 
    loginRequest, 
    reporteAll, 
    getClienteFacturaToSAPRequest,
    enviarPagoNoSicronizadoSAPRequest
 } from '../api/auth'

const DeudasContext = createContext();

export const useDeudas = () => {
    const context = useContext(DeudasContext);

    if (!context){
        throw new Error("useTask must be used within an AuthProvider");
    }
    return context;
}

export function DeudasProvider( {children} ){

    const [isAuthenticated, setIsAuthenticated] = useState(false); 
    const [deudas, setDeudas] = useState(null); 
    const [deudasActualizadas, setDeudasActualizadas] = useState(null); 
    const [isDeudasActualizadas, setIsDeudasActualizadas] = useState(false); 
    const [deudasCargadasAlArray, setDeudasCargadasAlArray] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingGenerarQR, setLoadingGenerarQR] = useState(false);
    const [miQR, setmiQR] = useState(null);
    const [miQRCargado, setmiQRCargado] = useState(false);
    const [errorConsultarDeuda, setErrorConsultarDeuda] = useState(false);
    const [errorGenerarQR, setErrorGenerarQR] = useState(false);
    const [detalleDeudasQRGenerado, setDetalleDeudasQRGenerado] = useState([]);
    const [loadingVerificarQR, setLoadingVerificarQR] = useState(false);
    const [loadingMostrarMisPagos, setLoadingMostrarMisPagos] = useState(false);
    const [isVerificado, setIsVerificado] = useState(false);
    const [mostrarMisPagos, setMostrarMisPagos] = useState(false);
    const [mostrarMisPagosArray, setMostrarMisPagosArray] = useState([]);
    const [QRPagadoConExito, setQRPagadoConExito] = useState(null);
    const [errorsQRPagadoConExito, setErrorsQRPagadoConExito] = useState([]);
    const [listaReporteFiltrado, setListaReporteFiltrado] = useState([]);
    const [loadingListaReporteFiltrado, setLoadingListaReporteFiltrado] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [user, setUser] = useState(null);  
    const [errorsLogin, setErrorsLogin] = useState([]); 
    const [getClienteFacturaToSAP, setGetClienteFacturaToSAP] = useState([]); 
    const [loadingClienteSAP, setLoadingClienteSAP] = useState(false); 


    const signin = async (user) => {
        try {
            setLoadingLogin(true);
            const res = await loginRequest(user);   
            if (res.data.status == 0) {
                setIsAuthenticated(true);
                setUser(res.data.data);
            }
            
            setLoadingLogin(false);
            setErrorsLogin([res.data.message]);
        } catch (error) {       
            setLoadingLogin(false);     
            /* if(Array.isArray(error.response.data)){
                return setErrors(error.response.data);
            }*/
            setErrorsLogin([error.response.data.message]); //convertimos a un arreglo el objeto message 
        }
    }

    const logout = () => {
        setIsAuthenticated(false)
        setUser(null)
        setDeudas(null); 
        setDeudasActualizadas(null); 

        setIsDeudasActualizadas(false); 
        setDeudasCargadasAlArray(false);
        setLoading(false);
        setLoadingGenerarQR(false);
        setmiQR(null);
        setmiQRCargado(false);
        setErrorConsultarDeuda(false);
        setErrorGenerarQR(false);
        setDetalleDeudasQRGenerado([]);
        setLoadingVerificarQR(false);
        setLoadingMostrarMisPagos(false);
        setIsVerificado(false);
        setMostrarMisPagos(false);
        setMostrarMisPagosArray([]);
        setQRPagadoConExito(null);
        setErrorsQRPagadoConExito([]);
        setListaReporteFiltrado([]);
        setLoadingListaReporteFiltrado(false);
        setLoadingLogin(false);
        setErrorsLogin([]);
        setGetClienteFacturaToSAP([]);
        setLoadingClienteSAP(false);
    }

    const getDeudas = async(data) => {        
        try {
            setLoading(true)
            const respuesta = await obtenerDeuda(data);

            if (respuesta.data.DetalleLotes.length <= 0 && respuesta.data.NombreCliente == ""){            
                setErrorConsultarDeuda(true);
            }else{
                setDeudas(respuesta.data) // actualizar
                setDeudasCargadasAlArray(true) // actualizar
                setIsAuthenticated(true)
            }
                 
            setLoading(false)
            // setErrorConsultarDeuda(false);
        } catch (error) {
            setErrorConsultarDeuda(true);
            console.log(error)
            setLoading(false)
        }
    }

    const obtenerClienteFacturaNoEnviadoSAP = async() => {
        try {
            setLoadingClienteSAP(true);
            const respuesta = await getClienteFacturaToSAPRequest();
            if (respuesta.data.status === 0) {
                setGetClienteFacturaToSAP(respuesta.data.data);
            }
            setLoadingClienteSAP(false);
        } catch (error) {
            console.log(error);
            setLoadingClienteSAP(false);
        }
    }

    const enviarClienteFacturaSAP = async(data) => {
        try {
            setLoadingClienteSAP(true);
            const respuesta = await enviarPagoNoSicronizadoSAPRequest(data);
            if (respuesta.data.status === 0) {                  
                setGetClienteFacturaToSAP([]);
                const resp = await getClienteFacturaToSAPRequest();                
                if (resp.data.status === 0) {                                      
                    setGetClienteFacturaToSAP(resp.data.data);
                }
            }
            setLoadingClienteSAP(false);
        } catch (error) {
            console.log(error);
            setLoadingClienteSAP(false);
        }
    }

    const generarNuevoQR = async(data) => {        
        try {
            setLoadingGenerarQR(true)
            const respuesta = await generarQR(data);
            if (respuesta.data.qr != '' && respuesta.data.qr != null){                
                setmiQR(respuesta.data);
                setmiQRCargado(true)
            }else{
                setErrorGenerarQR(true)
            }

            setLoadingGenerarQR(false)
        } catch (error) {
            console.log(error)
            setErrorGenerarQR(true)    
            setLoadingGenerarQR(false) 
        }
    }

    const verificarQRGenerado = async(data) => {        
        try {
            setLoadingVerificarQR(true);
            const respuesta = await verificarQR(data);

            setQRPagadoConExito(respuesta.data)
            if(respuesta.data.status == 0){
                setIsVerificado(true);
            }else{
                setErrorsQRPagadoConExito([respuesta.data.message])
            }                   
            setLoadingVerificarQR(false)
        } catch (error) {
            console.log(error)
            setLoadingVerificarQR(false)
        }
    }

    useEffect(() => {
        if(errorsQRPagadoConExito.length > 0){
            const timer = setTimeout(() => {
                setErrorsQRPagadoConExito([]);
            }, 5000);
            return () => clearTimeout(timer); //quita el timer
        }
    }, [errorsQRPagadoConExito]);

    useEffect(() => {
        if(errorsLogin.length > 0){
            const timer = setTimeout(() => {
                setErrorsLogin([]);
            }, 5000);
            return () => clearTimeout(timer); //quita el timer
        }
    }, [errorsLogin]);

    const consultarMisPagos = async(data) => {
        try {
            setLoadingMostrarMisPagos(true);
            const respuesta = await consultarPagos(data);            
            setMostrarMisPagosArray(respuesta.data)
            setLoadingMostrarMisPagos(false)
        } catch (error) {
            console.log(error);       
            setLoadingMostrarMisPagos(false);
        }
    }

    const reportefiltrarPorUsuario = async(data) => {        
        try {
            setLoadingListaReporteFiltrado(true)
            const respuesta = await reporte(data);

            if (respuesta.data.status == 0) {
                setListaReporteFiltrado(respuesta.data.data);
            }

            setLoadingListaReporteFiltrado(false)
        } catch (error) {
            console.log(error)
            setLoadingListaReporteFiltrado(false) 
        }
    }

    const reportefiltrarUsuarioAll = async(data) => {        
        try {
            setLoadingListaReporteFiltrado(true)
            const respuesta = await reporteAll(data);

            if (respuesta.data.status == 0) {
                setListaReporteFiltrado(respuesta.data.data);
            }

            setLoadingListaReporteFiltrado(false)
        } catch (error) {
            console.log(error)
            setLoadingListaReporteFiltrado(false) 
        }
    }

    return (
        <DeudasContext.Provider value={{
            getDeudas,
            deudas,
            deudasCargadasAlArray,
            setDeudasCargadasAlArray,
            loading,
            generarNuevoQR,
            loadingGenerarQR,
            miQR,
            errorConsultarDeuda,
            setErrorConsultarDeuda,
            miQRCargado,
            setmiQRCargado,
            errorGenerarQR,
            setErrorGenerarQR,
            detalleDeudasQRGenerado,
            setDetalleDeudasQRGenerado,
            verificarQRGenerado,
            isVerificado,
            setIsVerificado,
            loadingVerificarQR,
            setLoadingGenerarQR,
            consultarMisPagos,
            mostrarMisPagos,
            setMostrarMisPagos,
            mostrarMisPagosArray,
            setMostrarMisPagosArray,
            setDeudasActualizadas,
            deudasActualizadas,
            loadingMostrarMisPagos,
            setLoadingMostrarMisPagos,
            setQRPagadoConExito,
            QRPagadoConExito,
            errorsQRPagadoConExito,
            setErrorsQRPagadoConExito,
            reportefiltrarPorUsuario,
            listaReporteFiltrado,
            setListaReporteFiltrado,
            loadingListaReporteFiltrado,
            setLoadingListaReporteFiltrado,
            isAuthenticated,
            setIsAuthenticated,
            loadingLogin,
            setLoadingLogin,
            signin,
            user,
            setUser,
            errorsLogin,
            reportefiltrarUsuarioAll,
            logout,
            obtenerClienteFacturaNoEnviadoSAP,
            getClienteFacturaToSAP,
            setGetClienteFacturaToSAP,
            enviarClienteFacturaSAP,
            loadingClienteSAP,
        }}>            
            { children }
        </DeudasContext.Provider>
    );
}