import React from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoIMG from './../assets/img/logo/Urbanor.jpg'
import autoTable from 'jspdf-autotable'

export const PrintToPDF = ({ misDeudas, seleccion, nombreCliente}) => {

    function print2PDF() {
        const elementContent = this.nativeElement;
        const nameProforma = this.presupuesto.id_budget.toString()+" "
        const name2 = this.presupuesto.client?this.presupuesto.client.full_name:this.presupuesto.contact.name+" "+this.presupuesto.contact.lastname
        html2canvas(this.elfooter.nativeElement).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'letter');
    
          const pageSize = pdf.internal.pageSize;
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          pdf.addImage(imgData, 'PNG', 5, pageHeight - 30, pageSize.width - 15, 15);
          pdf.html(elementContent, {
            callback: function (doc) {
              doc.save("prof"+nameProforma+name2+'.pdf');
            },
            margin: [0, 0, 0, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 190,
            windowWidth: 800,
          });
        });
    
        this.visibleSidebar = false;
        this.showDialog = false;
    }

    function generarPDF(){      
        const pdfDoc  = new jsPDF();
        //doc.text("Hello world!", x, y);
        //doc.text("Hello world!", 10, 20);
        //doc.save("a4.pdf");
        // Título
        pdfDoc.text('Deudas Pendientes', pdfDoc.internal.pageSize.width / 2, 47, { align: 'center' });

        // Logo
        const logo = new Image();
        logo.src = logoIMG; // Reemplaza con la ruta real de tu logo
        pdfDoc.addImage(logo, 'PNG', 10, 10, 25, 25); // Ajusta las coordenadas y dimensiones según tu diseño
        pdfDoc.line(10, 37, pdfDoc.internal.pageSize.width-10, 37)

        // Información de la persona y fecha de emisión
        //pdfDoc.text('Nombre: HENRY MANUEL CARI ORTEGA', 10, 60).setFontSize(10);
        pdfDoc.text(`Nombre: ${nombreCliente}`, 10, 60);
        pdfDoc.text(`Lote: ${seleccion}`, 10, 70);
        pdfDoc.text(`Fecha de Emisión: ${new Date().toLocaleDateString()}`, 10, 80);

        // Crear la tabla con el detalle del pago
        const headers = ['Item', 'Descripción', 'Cuota', 'Multa', 'Total'];
        const data = [
          ['1', 'CUOTA 23', '1216.26', '167.03', '1383.29'],
          ['2', 'CUOTA 24', '1216.26', '215.75', '1432.01'],
          ['3', 'CUOTA 25', '1216.26', '208.80', '1425.06'],
          // Agrega más filas según sea necesario
        ];

        const arrayDataDetalle = [];
        misDeudas.forEach((detalle, indice) => {
          let array = [];
          array.push(
              indice+1, 
              `${detalle.AbreviaturaConceptoPago[0]} ${detalle.NroCuota}`,
              detalle.MontoConcepto[0].toFixed(2),
              detalle.MontoConcepto.length > 1 ? detalle.MontoConcepto[1].toFixed(2) : "0",
              detalle.TotalCuotaMulta.toFixed(2)
          );
          arrayDataDetalle.push(array)
        });

        /* autoTable({
          head: [headers],
          body: data,
          startY: 80, // Ajusta la posición de inicio de la tabla
        }); */
        autoTable(pdfDoc, {
          head: [headers],
          //margin: { top: 90 },
          startY: 90,
          body: arrayDataDetalle,
        })

        // Guardar o mostrar el PDF
        pdfDoc.save('BoletaPago.pdf');
        // Opcionalmente, puedes abrirlo en una nueva ventana/tab:
        // pdfDoc.output('dataurlnewwindow');
    }
  return (
    <div>
        <button className='btn btn-info text-white my-1' onClick={generarPDF}>Descargar PDF</button>
    </div>
  )
}
