import React from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { useDeudas } from './../context/DeudasContext'
import './Loader.css';

export const ConsultarPagos = ({ mostrarMisPagosArray} ) => {
  const { loadingMostrarMisPagos } = useDeudas();

  //console.log(mostrarMisPagosArray)
  return (
    <div className='d-flex justify-content-center align-items-center'>
      {
        mostrarMisPagosArray.length > 0 ?
          <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll' style={{width: '35rem'}}>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th>Transacción</th>
                  <th>Lote</th>
                  <th>Descripción</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>            

                {
                  mostrarMisPagosArray.map((detalle, indice) => (
                                  
                      <tr key={`${detalle.IdDeudaExterna}`}>
                          <td data-titulo="Lote"> 
                            {                          
                              `${detalle.tipoTransaccion}`
                            } 
                          </td>                      
                          <td data-titulo="Lote"> 
                            {                          
                              `${detalle.nombreLote}`
                            } 
                          </td>                      
                          <td data-titulo="Descripcion"> 
                            {                          
                              `${detalle.AbreviaturaConceptoPago}`
                            } 
                          </td>                      
                          <td data-titulo="Total"> 
                            {
                              detalle.TotalCuotaMulta.toFixed(2)
                            } 
                          </td>                                                                                      
                      </tr>               
                  ))                          
                } 
              </tbody>                
            </table> 
          </div>
        :
        (
          !loadingMostrarMisPagos ?
           <h5>Aún no tienes pagos en este Lote</h5> : undefined
        )        
      }

      <div className='d-flex justify-content-center align-items-center'>
        {
          loadingMostrarMisPagos && (
            <div className="d-flex justify-content-center">
                <div className="loader2"></div>
            </div> 
          )
        }
      </div>


      <Toaster
        position="top-right"
        toastOptions={{
          style: {
              background: '#363636',
              color: '#fff',
          },
        }}
      />
               
    </div>
  )
}
