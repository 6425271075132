import React, { useEffect, useState } from 'react'
import { useDeudas } from './../context/DeudasContext'
import { useNavigate } from 'react-router-dom'
import './../components/Loader.css';
import { Toaster, toast } from 'react-hot-toast'
import { ConsultarPagos } from './ConsultarPagos';
import ImprimirPDFPage from '../pages/ImprimirPDFPage';
import { PrintToPDF } from '../pages/PrintToPDF';
import "./../estilo.css";
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'

export const MostrarDeudas = ({ misDeudas, seleccion, codigo, setCurrentPageSelected, nombreCliente, factNombre }) => {

  const { 
    generarNuevoQR, 
    loadingGenerarQR, 
    miQRCargado, 
    errorGenerarQR, 
    setErrorGenerarQR, 
    setDetalleDeudasQRGenerado,
    consultarMisPagos,
    mostrarMisPagos,
    setMostrarMisPagos,
    mostrarMisPagosArray,
    setMostrarMisPagosArray
  } = useDeudas();

  const [arregloSeleccionado, setArregloSeleccionado] = useState([]);
  const [mostrarPDF, setMostrarPDF] = useState(false);

  const navigate = useNavigate();


  const handleChangeCheckBox = (event) => { 
    //const { name, checked } = event.target;
    const { value, checked } = event.target;

    if(checked){
      let valorSeleccionado = value;
      const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");      
      
      checkBoxSelected.forEach((item, indice) => {        
        if(item.checked && indice < checkBoxSelected.length - 1){
          checkBoxSelected[indice+1].disabled = false
        }
        if(item.checked && indice == checkBoxSelected.length - 1){
          checkBoxSelected[indice].disabled = false
        }
      });

      setArregloSeleccionado([...arregloSeleccionado, value])
    }else{
      //desmarcar
      const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");
      checkBoxSelected.forEach((item, indice) => {
        if(item.id == value){ //desmarcar y desabilitar
          descarcar_desabilitar(item, indice, checkBoxSelected);
        }
      });

      //setArregloSeleccionado(arregloSeleccionado.filter( idGuardados => idGuardados !== value))
    }

  }

  function descarcar_desabilitar(item, indice, checkBoxSelected){
    let arregloSeleccionadoCopia = arregloSeleccionado;
    //checkBoxSelected[indice].checked = false;
    //checkBoxSelected[indice].disabled = true;
    arregloSeleccionadoCopia = arregloSeleccionadoCopia.filter( idGuardados => idGuardados != checkBoxSelected[indice].value )
    indice++;
    while(indice < checkBoxSelected.length){
      checkBoxSelected[indice].checked = false;
      checkBoxSelected[indice].disabled = true;
      arregloSeleccionadoCopia = arregloSeleccionadoCopia.filter( idGuardados => idGuardados != checkBoxSelected[indice].value )      
      indice++;
    }
    
    setArregloSeleccionado(arregloSeleccionadoCopia)
    //setArregloSeleccionado(arregloSeleccionado.filter( idGuardados => idGuardados !== value ))
  }

  const handleChangeCheckBoxCopias = (event) => {       
    const { value, checked } = event.target;

    if(checked){
      setArregloSeleccionado([...arregloSeleccionado, value])
    }else{
      setArregloSeleccionado(arregloSeleccionado.filter( idGuardados => idGuardados !== value))
    }

  }

  const handleConsultarPagos = () => {
    setArregloSeleccionado([]);
    setMostrarMisPagos( (prev) => !prev )
    setCurrentPageSelected(!mostrarMisPagos);
    if(!mostrarMisPagos){  
      setMostrarMisPagosArray([]);    
      consultarMisPagos({FacturaNITCI: codigo, AbreviaturaSubnivel: seleccion});
    }    
  }

  const handleGenerarQR = () => {
    if (arregloSeleccionado.length == 0){
      toast.error("Error. Debes seleccionar una deuda para generar el QR.")
      return;
    }

    let deudaTotal = 0;
    let sumaDeudaMulta = 0;
    let listaDetalleDeudas = [];
    let concepto = "";
    let detalleQRDeudas = [];
    let nombreBanco = "BCP"
    concepto = `${nombreBanco} LOTE -> ${seleccion} `;
    arregloSeleccionado.map( (elemento) => {
      misDeudas.forEach(element => {
        if(element.IdDeudaExterna == elemento){
          sumaDeudaMulta = element.MontoConcepto[0] + (element.MontoConcepto.length > 1 ? element.MontoConcepto[1]: 0)                 
          deudaTotal = deudaTotal + sumaDeudaMulta;        
          listaDetalleDeudas.push({...element, nombreLote: seleccion}); // nombre del Lote
          detalleQRDeudas.push({
            nombreLote: seleccion,
            nroCuota: element.NroCuota,
            montoCuota: element.MontoConcepto[0],
            montoMulta: element.MontoConcepto.length > 1 ? element.MontoConcepto[1] : 0,
            montoTotal: element.TotalCuotaMulta,
            codigo: codigo.toString(),
            factNombre: factNombre
          });
          concepto = concepto + element.AbreviaturaConceptoPago[0] + ' ' + element.NroCuota;
        } 
      });      
    });     

    generarNuevoQR({amount: Number(deudaTotal.toFixed(2)), glosa: concepto, detalleQRDeudas});
    setDetalleDeudasQRGenerado(listaDetalleDeudas);
  }

  useEffect( () => {    
    if(miQRCargado) navigate('/generarqr')
  }, [miQRCargado])

  useEffect( () => {
    if(errorGenerarQR) {
        toast.error("Error al generar el QR, intete más tarde.")
        setErrorGenerarQR(false)
    }
  }, [errorGenerarQR]);

  useEffect( () => {
    setArregloSeleccionado([]);
  }, [seleccion]);
  
  return (
    <>
      <div className='d-flex justify-content-center align-items-center mb-2'> 
        <div className='d-flex justify-content-between align-items-center mt-0' style={{width: '35rem'}}> 
          {
            !mostrarMisPagos ? 
            (
              <>
                <button className='btn btn-info text-white my-1' onClick={handleGenerarQR}>Generar QR</button>
                <PrintToPDF misDeudas={misDeudas} seleccion={seleccion} nombreCliente={nombreCliente}/>
                {/* <button className='btn btn-info text-white my-1' onClick={handleGenerarQR}>Descargar PDF</button> */}
              </>
            )
            
            : undefined
          }
          
          <div className='d-flex justify-content-center align-items-center'>
            {
              loadingGenerarQR && (
                <div className="d-flex justify-content-center">
                    <i className="loader2"></i> <span>Generando QR ...</span>
                </div> 
              )
            }
          </div>
          {
            mostrarMisPagos ?
            (<button className='btn btn-info text-white my-1' onClick={handleConsultarPagos}>Volver a Mis Deudas</button>)
            :
            <button className='btn btn-success text-white my-1' onClick={handleConsultarPagos}>Consultar Mis Pagos</button>
          }          
        </div>
      </div>

      {
        !mostrarMisPagos ?        
          <div className='d-flex justify-content-center align-items-center mb-2' >
            <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll' style={{width: '35rem'}}> 
            {/* <table className='table table-hover' style={{ width: '35rem'}}> */}
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Descripción</th>
                    <th>Fecha de <br /> vencimiento</th>
                    <th>Cuota</th>
                    <th>Multa</th>
                    <th>Total</th>
                    <th>Pagar</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    misDeudas.length === 0 ? <tr><td colSpan="10" align='center'>Cargando ...</td></tr> : undefined
                  }

                  { 
                    misDeudas.map((detalle, indice) => (
                                    
                        <tr key={`${detalle.IdDeudaExterna}-${detalle.AbreviaturaConceptoPago}`}>
                            <td data-titulo="Item"> {indice + 1} </td>
                            <td data-titulo="Descripcion"> 
                              {                          
                                `${detalle.AbreviaturaConceptoPago[0]} ${detalle.NroCuota}`
                              } 
                            </td>
                            <td data-fvencimiento="F.vencimiento"> 
                              {                          
                                `${detalle.FechaVencimiento}`
                              } 
                            </td>
                            <td data-titulo="Cuota"> 
                              {
                                detalle.MontoConcepto[0].toFixed(2)
                              } 
                            </td>
                            <td data-titulo="Multa"> 
                              {
                                detalle.MontoConcepto.length > 1 ? detalle.MontoConcepto[1].toFixed(2) : "0"
                              } 
                            </td>
                            <td data-titulo="Total"> 
                              {
                                detalle.TotalCuotaMulta.toFixed(2)
                              } 
                            </td>

                            {
                              indice == 0 ? 
                              <td data-titulo="Pagar"> 
                                <input type='checkbox' className='checkBoxSelect'
                                  id={`${detalle.IdDeudaExterna}`} 
                                  disabled={false}
                                  value={`${detalle.IdDeudaExterna}`} 
                                  onChange={handleChangeCheckBox}/>
                              </td>
                              : 
                              <td data-titulo="Pagar"> 
                                <input type='checkbox' className='checkBoxSelect'
                                  id={`${detalle.IdDeudaExterna}`}
                                  disabled={true}
                                  value={`${detalle.IdDeudaExterna}`} 
                                  onChange={handleChangeCheckBox}/>
                              </td>
                            }                                                                                          
                        </tr>               
                    ))
                  } 
                </tbody>                
              </table>        
            </div>
          </div>
        :
          <ConsultarPagos 
            mostrarMisPagosArray = {mostrarMisPagosArray}
          />
      }
            

      <Toaster
        position="top-right"
        toastOptions={{
          style: {
              background: '#363636',
              color: '#fff',
          },
      }}
      />
    </>
  )
}
