import React, { useEffect } from 'react';
import ImageB64 from './ImageB64'
import { useDeudas } from './../context/DeudasContext'
import { DetalleQRGenerado } from './DetalleQRGenerado'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import MyAlertComponent from './MyAlertComponent '

export const GenerarQR = () => {
    const { 
      miQR, 
      setmiQRCargado, 
      verificarQRGenerado, 
      isVerificado, 
      setIsVerificado, 
      loadingVerificarQR, 
      deudas,
      detalleDeudasQRGenerado,
      setQRPagadoConExito,
      QRPagadoConExito,
      errorsQRPagadoConExito,
      setErrorsQRPagadoConExito
    } = useDeudas();

    const navigate = useNavigate();

    const handleVerificarQR = () => {           
      if (QRPagadoConExito != null){
        // setQRPagadoConExito(null)
      }
      verificarQRGenerado({ qrId: miQR.id, data: deudas, detalleDeudasQRGenerado})
    }

    useEffect( () => {
      setmiQRCargado(false);
    }, [])

    useEffect( () => {   
      if(isVerificado) {
        setIsVerificado(false);
        navigate('/verificado')
      }
    }, [isVerificado])


  return (
    <div>
        <div className="d-flex justify-content-center mt-2">
            { miQR ? (<ImageB64 base64String = {miQR.qr} isDisable = {false}/>) : <h3>debes seleccionar tus deudas para generar un nuevo QR.</h3>}
        </div>

        <div>
            { miQR ? (<DetalleQRGenerado/>) : undefined}
        </div>
        
        <div className="d-flex justify-content-center mt-1">          
          {  
            loadingVerificarQR && (
              <div className="d-flex justify-content-center">
                  <i className="loader2"></i> <span>Verificando QR ...</span>
              </div> 
            )
          }
        </div>

        <div className="d-flex justify-content-center mt-1">          
          {
            errorsQRPagadoConExito.map((error, i) => (
              <MyAlertComponent mensaje={error} key={i}/>              
            ))
          }
        </div>
        

        <div className="d-flex justify-content-center mt-1">          
          { miQR ? (<button className='btn btn-info text-white my-1'onClick={handleVerificarQR}>Verificar Pago</button>) : undefined}
        </div>

        {/* {QRPagadoConExito != null ? 
          QRPagadoConExito.status == 1 ? toast.error("Error. El QR aun no ha sido pagado") : undefined 
          : undefined } */}

        {/* {
          QRPagadoConExito != null && QRPagadoConExito.status == 1 
          ? 
            <h1>aun no se pago</h1>
          :
            undefined
        } */}

        <Toaster
          position="top-right"
          toastOptions={{
            style: {
                background: '#363636',
                color: '#fff',
            },
          }}
        />

    </div>
  )
}
