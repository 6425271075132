import React, { useEffect, useState } from 'react'

function CheckBoxMultiple() {
    const [categorias, setCategorias] = useState([]);
    const [arregloSeleccionado, setArregloSeleccionado] = useState([]);
    useEffect(() => {
        fetch("https://radioondapopular.com/wp-json/wp/v2/categories/?per_page=100")
        .then(response => response.json())
        .then(data => setCategorias(data.map( ({id, name}) => ({id, name}))))
    },[])
    
    const handleChage = (event) => {
        // console.log(event.target)  // event.target[0]
        const {value, checked} = event.target;
        if(checked){
            setArregloSeleccionado([...arregloSeleccionado, value])
        }else{
            setArregloSeleccionado(arregloSeleccionado.filter( idGuardados => idGuardados !== value))
        }
    }

    // console.log(arregloSeleccionado)

  return (
    <div>
        <h1>Deudas</h1>
        <div>            
            {categorias.map( ({id, name}) => (
                <label key={id}>
                    <input type='checkbox' value={id} onChange={handleChage}/>
                    {name}
                </label>
            ))}
        </div>
    </div>
  )
}

export default CheckBoxMultiple