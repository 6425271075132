import './App.css';
import { Link, Route, Routes, useParams, Outlet, NavLink } from 'react-router-dom';
// import ImagenB64 from './pages/ImagenB64'
import CheckBoxMultiple from './pages/CheckBoxMultiple';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Home } from './pages/Home'
import { Deuda } from './pages/Deuda';
import { DeudasProvider } from './context/DeudasContext'
import { GenerarQR } from './components/GenerarQR';
import { VerificarPago } from './pages/VerificarPago';
import { PageStatic } from './pages/PageStatic';
import { Historico } from './pages/Historico';
//import Navbar from './components/Navbar';
import Navbar from './components/Navbar2'; //NavBar
import ProtectedRoute from './ProtectedRoute';
import Login from './pages/login/Login';
import { SincronizarSAP } from './pages/sincronizar.sap/SincronizarSAP';
//Route --> cual es el path en el que tiene que renderizarse un elemento


function App() {
  return (
    <div className="App">
      <DeudasProvider>
        <Navbar/>
        <Routes> 
          <Route path='/' element={ <Home /> } />       
          <Route path='/login' element={ <Login /> } />       
          <Route path='/checkbox' element={ <CheckBoxMultiple />} />          

          <Route element={ <ProtectedRoute/> }>
              <Route path='/deudas' element={ <Deuda />} /> 
              <Route path='/generarqr' element={ <GenerarQR />} />            
              <Route path='/verificado' element={ <VerificarPago />} />            
              <Route path='/pagestatic' element={ <PageStatic />} />            
              <Route path='/historico' element={ <Historico />} /> 
              <Route path='/sincronizarSAP' element={ <SincronizarSAP />} /> 
          </Route>    
          
          <Route path='*' element={ <h1>Not Found</h1> } />
        </Routes>
      </DeudasProvider>
    </div>
  );
}

export default App;
