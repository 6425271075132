import React, { useEffect, useState } from 'react'
import TablaSincronizarSAP from './TablaSincronizarSAP';
import { useDeudas } from './../../context/DeudasContext'

const headersAdmin = ['Enviar', 'DocEntryFac', 'Nro. Factura', 'Cliente', 'Nombre Factura', 'NIT Factura', 'Fecha', 'Monto'];


export const SincronizarSAP = () => {

    const { 
        obtenerClienteFacturaNoEnviadoSAP, 
        getClienteFacturaToSAP, 
        setGetClienteFacturaToSAP,
        enviarClienteFacturaSAP,
        loadingClienteSAP
    } = useDeudas();

    const [arregloSeleccionado, setArregloSeleccionado] = useState([]);

    const handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;        
        const arrayTodoSeleccionado = [];
        const arraySeleccionado = [];

        if(name === "allSelect"){
            const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");

            if (checked) {
                checkBoxSelected.forEach((item, indice) => {     
                    checkBoxSelected[indice].checked = true;
                    arrayTodoSeleccionado.push(item.name);                    
                });
                setArregloSeleccionado(arrayTodoSeleccionado)

            }else{
                checkBoxSelected.forEach((item, indice) => {     
                    checkBoxSelected[indice].checked = false                       
                });
                setArregloSeleccionado([])
            }                        
        }else{
            const checkBoxSelected = document.querySelectorAll(".checkBoxSelect");
            const checkBoxSelectedAll = document.getElementById("allSelect");
            let isAllSelect = true;

            if (checked) {

                checkBoxSelected.forEach((item, indice) => {                     
                    if (item.checked == false) {
                        isAllSelect = false
                    }else{
                        arraySeleccionado.push(item.name)
                    }
                });                
                
                if (isAllSelect) {
                    checkBoxSelectedAll.checked = true;
                    setArregloSeleccionado(arraySeleccionado);
                }else{ //NO ESTA SELECCIONADO TODO                    
                    checkBoxSelectedAll.checked = false;
                    setArregloSeleccionado([...arraySeleccionado]);
                }
            }else{
                checkBoxSelectedAll.checked = false;                
                setArregloSeleccionado(arregloSeleccionado.filter( nroTransaccion => nroTransaccion !== name ))
            }                        
        }        
    }

    const handleEnviarSAP = () => {  
        if (arregloSeleccionado.length > 0) {

            const arregloFiltrado = [];
            for (let index = 0; index < arregloSeleccionado.length; index++) {
                const element = arregloSeleccionado[index];            
                // const objFiltrado = getClienteFacturaToSAP.filter((data) => data.nroTransaccion == element)   
                const objFiltrado = getClienteFacturaToSAP.find((data) => data.nroTransaccion == element)               
                arregloFiltrado.push(objFiltrado);
            }            
            enviarClienteFacturaSAP(arregloFiltrado);
        }         
    }

    useEffect(() => {
        setGetClienteFacturaToSAP([]);
        obtenerClienteFacturaNoEnviadoSAP();
    }, []);

    useEffect(() => {    
        setArregloSeleccionado([]);
        const checkBoxSelectedAll = document.getElementById("allSelect");
        checkBoxSelectedAll.checked = false;
    }, ['getClienteFacturaToSAP']);
    

  return (
    <>
        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center mb-2' >
                    <div className='d-flex justify-content-between align-items-center mt-0 px-1 tabla_scroll' style={{width: '50rem'}}> 
                        <div className='form-check'>
                            <input type='checkbox' className='form-check-input'
                                name='allSelect'
                                id='allSelect'
                                disabled={false}
                                value=""
                                onChange={handleChangeCheckBox}
                            />
                            <label className='form-check-label ms-2'>Seleccionar Todo</label>
                        </div>                         

                        <button className='btn btn-info text-white my-1' onClick={handleEnviarSAP}>Enviar Pagos SAP</button>
                    </div>
                </div>
            </div>
        </div>

        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center mb-2' >
                    <div className='d-flex justify-content-center align-items-center mt-0 tabla_scroll' style={{width: '50rem'}}> 
                        <div className='d-flex justify-content-center align-items-center'>
                            {
                                loadingClienteSAP && (
                                    <div className="d-flex justify-content-center">
                                        <div className="loader2"></div>
                                    </div> 
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center mb-2' >
                    <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll' style={{width: '50rem'}}> 
                        <TablaSincronizarSAP headers={headersAdmin} dataBody={getClienteFacturaToSAP} handleChangeCheckBox={handleChangeCheckBox}/> 
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
