import React, { useEffect, useState } from 'react'
import { useDeudas } from '../context/DeudasContext'
import "./../estilo.css";

export const DetalleQRGenerado = () => {

    const [totales, setTotales] = useState(0);

    const { detalleDeudasQRGenerado } = useDeudas();

    function calcularTotales(){
        let total = 0;
        detalleDeudasQRGenerado.forEach(deuda => {
            total = total + deuda.TotalCuotaMulta;
        });
        return total.toFixed(2);
    }

    useEffect( () => {        
        setTotales(calcularTotales())
    }, [])

  return (
    <>
        <div className='d-flex justify-content-center align-items-center mb-2'> 
          <div className='d-flex justify-content-center align-items-center mt-0' style={{width: '35rem'}}>
              <h5>El QR Generado es por <span style={{fontWeight: 'bold'}}> {totales} BOB </span></h5>
          </div>
        </div>

        <div className='d-flex justify-content-center align-items-center'> 
          <div className='d-flex justify-content-center align-items-center mt-0' style={{width: '35rem'}}>              
              <h5 className='my-2' style={{fontWeight: 'bold'}}>URBANIZACIONES DEL NORTE SA</h5>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center'> 
          <div className='d-flex justify-content-center align-items-center mt-0' style={{width: '35rem'}}>              
              <h5 className='my-1' style={{fontWeight: 'bold'}}>NIT: 317038022</h5>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center mb-2'> 
          <div className='d-flex justify-content-center align-items-center mt-0' style={{width: '35rem'}}>              
                <h5 className='my-2'>{ `Lote: ${detalleDeudasQRGenerado[0].nombreLote}` }</h5>
          </div>
        </div>

        <div className='d-flex justify-content-center align-items-center mb-2'> 
          <div className='d-flex justify-content-center align-items-center mt-0' style={{width: '35rem'}}>              
              <h5 className='my-2'>Detalle Del QR Generado</h5>
          </div>
        </div>

        
        <div className='d-flex justify-content-center align-items-center mb-2' >
            <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll' style={{width: '35rem'}}> 
              <table className='table table-hover'>                
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Descripción</th>
                      <th>Cuota</th>
                      <th>Multa</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    { 
                      detalleDeudasQRGenerado.map((detalle, indice) => (
                                      
                          <tr key={`${detalle.IdDeudaExterna}`}>
                              <td data-titulo="Item"> {indice + 1} </td>
                              <td data-titulo="Descripcion"> 
                                {                          
                                  `${detalle.AbreviaturaConceptoPago[0]} ${detalle.NroCuota}`
                                } 
                              </td>
                              <td data-titulo="Cuota"> 
                                {
                                  detalle.MontoConcepto[0].toFixed(2)
                                } 
                              </td>
                              <td data-titulo="Multa"> 
                                {
                                  detalle.MontoConcepto.length > 1 ? detalle.MontoConcepto[1].toFixed(2) : "0"
                                } 
                              </td>
                              <td data-titulo="Total"> 
                                {
                                  detalle.TotalCuotaMulta.toFixed(2)
                                } 
                              </td>                                                                                     
                          </tr>               
                      ))
                    } 
                    <tr style={{border: 'none'}}>
                        <td colSpan={3} style={{border: 'none'}}></td>
                        <td style={{fontWeight: 'bold', border: 'none'}}>Total</td>
                        <td style={{fontWeight: 'bold', border: '1px solid #E3E5E7'}}> {totales} </td>
                    </tr>
                  </tbody>
                </table> 
            </div>              
        </div>              
    </>
  )
}
