import React from 'react'
import {Link, NavLink, useLocation } from 'react-router-dom'
import { useDeudas } from '../context/DeudasContext'
import { UserRole } from './../constants/defaultValues'
//import logo from './../assets/img/logo/AZUL_VB.png'
import logoPrincipal from './../assets/img/logo/blanco.png'
import e1 from './../assets/img/navbar/1.png'
import e2 from './../assets/img/navbar/2.png'
import e3 from './../assets/img/navbar/3.png'
import e4 from './../assets/img/navbar/4.png'
import './Navbar.css';

function Navbar2() {
  const { isAuthenticated, user, logout } = useDeudas();
  
  const location = useLocation();

  const isRootPath = location.pathname === '/';
  const isLoginPath = location.pathname === '/login';
  // height: '3.2rem', width: "11.5rem"
  // height: '4.4rem', width: "12.7rem"
  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo-container">
          <img src={logoPrincipal} alt="Logo" className="navbar-logo" style={{ height: '4.4rem', width: "13.3rem"  }} />
          <div className="navbar-logos">
            <img src={e1} alt="Logo1" className="navbar-logo" />
            <img src={e2} alt="Logo2" className="navbar-logo" />
            <img src={e3} alt="Logo3" className="navbar-logo" />
            <img src={e4} alt="Logo4" className="navbar-logo" />
          </div>
        </div>              
      </div>
      <div className="navbar-options">
        {
          isAuthenticated ? (
            user && (user.role == UserRole.ADMIN || user.role == UserRole.CONTADOR) ? ( /* cambiar por ADM */
              <>
                  <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/sincronizarSAP'>Sincronizar SAP</NavLink> </li>
                  <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/historico'>Histórico</NavLink> </li>
                  <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/deudas'>Mis deudas</NavLink> </li>
                  <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/' onClick={ () => { logout() }}>Salir</NavLink> </li>                        
              </>
            ) : (
              <>
                <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/historico'>Histórico</NavLink> </li>
                <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/deudas'>Mis deudas</NavLink> </li>
                <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/' onClick={ () => { logout() }}>Inicio</NavLink> </li>
              </>
            )
              
          ) : (                  
            undefined
          )
        }
        
        {
              isRootPath && (
                <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/login'>Login</NavLink> </li>
              )
            }
            
            {
              isLoginPath && (
                <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/'>Inicio</NavLink> </li>
              )
            }         
        </div>
    </div>
  )
}
//<a href="/historicos" className="navbar-option">Históricos</a>    
export default Navbar2