import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDeudas } from './../context/DeudasContext'
import Datepicker,{ registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { UserRole } from './../constants/defaultValues'
import './../estilo.css'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

import 'react-datepicker/dist/react-datepicker.css';
import { Toaster, toast } from 'react-hot-toast'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TablaReporteHistoricoExcel from '../components/TablaReporteHistoricoExcel';
// import { TablaReporteHistorico } from '../components/TablaReporteHistorico';
const TablaReporteHistorico = lazy(()=> import('./../components/TablaReporteHistorico'))
dayjs.extend(utc);
registerLocale("es", es);


const headersCliente = ['Fecha Hora', 'Lote', 'Descripción', 'Cuota', 'Multa', 'Total','Estado'];
const headersAdmin = ['Nro. Transacción','Fecha Hora', 'Cliente', 'Lote', 'Descripción', 'Cuota', 'Multa', 'Total','Estado'];

export const Historico = () => {
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");

    const { deudas, reportefiltrarPorUsuario, reportefiltrarUsuarioAll, listaReporteFiltrado, loadingListaReporteFiltrado, user } = useDeudas();

    const onChangeFechaInicio = (fecha) => {
        setFechaInicio(fecha)
    }

    const onChangeFechaFin = (fecha) => {
        setFechaFin(fecha)
    }

    const validarFecha = (fechaInicio, fechaFin, arrayError) =>{
        if ((fechaInicio == "" || fechaInicio == null) && (fechaFin == "" || fechaFin == null)) {
            arrayError.push(1);
            return toast.error("Fecha de Inicio y Fecha Fin no puede estar vacia para filtrar los datos");
        }

        if (fechaInicio == "" || fechaInicio == null) {
            arrayError.push(1);
            return toast.error("Fecha de Inicio no puede estar vacia");
        }

        if (fechaFin == "" || fechaFin == null) {
            arrayError.push(1);
            return toast.error("Fecha Fin no puede estar vacia");
        }

        if (fechaInicio > fechaFin) {
            arrayError.push(1);
            return toast.error("Fecha Fin no puede estar vacia");
        }
    }

    const handleFiltrarPorUsuario = () => {        
        const codigoNIT = deudas.DatosFiscales.FacturaNITCI;

        let arrayError = [];
        validarFecha(fechaInicio, fechaFin, arrayError);
        
        if(arrayError.length == 0){            
            fechaFin.setHours(23, 59, 59)
            // console.log(fechaFin.toLocaleDateString('es-Es'))
            reportefiltrarPorUsuario({codigoNIT, fechaInicio, fechaFin});
        }        
    }

    const handleFiltrarAll = () => {    
        let arrayError = [];
        validarFecha(fechaInicio, fechaFin, arrayError);
        
        if(arrayError.length == 0){            
            fechaFin.setHours(23, 59, 59)            
            reportefiltrarUsuarioAll({fechaInicio, fechaFin});
        }        
    }
    
    return (
        <>
            <div className='row mx-2 mt-4'>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-center' >
                        <div className='d-flex justify-content-between align-items-center mt-0 tabla_reporte'>
                            <h6>Fecha de Inicio</h6>
                            <h6>Fecha de Fin</h6>
                        </div>                        
                    </div>
                </div>                
            </div>            

            <div className='row' style={{margin: 0}}>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-center mb-2' >                       
                        <div className='d-flex justify-content-between align-items-center mt-0 tabla_reporte_50'>                            
                            <Datepicker selected={fechaInicio} onChange={onChangeFechaInicio} locale="es" className='pickers' dateFormat="dd-MM-yyyy"/>
                            <Datepicker selected={fechaFin} onChange={onChangeFechaFin} locale="es" className='pickers' dateFormat="dd-MM-yyyy"/>
                        </div>
                    </div>
                </div>                
            </div>
            
            <div className='row mx-2 mt-4'>
                <div className='col'>
                  <h3>Movimientos</h3>
                </div>
            </div>            

            <div className='row' style={{margin: 0}}>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-center mb-2' >
                        {
                            user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR)? (
                                <div className='d-flex justify-content-between align-items-start mt-0 tabla_reporte_50'>
                                                                           
                                    <ReactHTMLTableToExcel
                                        id='botonExportarExcel'
                                        className= 'btn btn-success text-white my-1'
                                        table= 'tabla_historico'
                                        filename= 'Reporte_Movimientos'
                                        sheet= 'Pagina 1'
                                        buttonText= 'Exportar a Excel'
                                    /> 
                                    <button className='btn btn-info text-white my-1' 
                                        style={{width: '25%', marginRight: '0px'}} 
                                        onClick={user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR) ? handleFiltrarAll : handleFiltrarPorUsuario}>
                                            Filtrar
                                    </button>                                
                                </div>
                            ) : (
                                <div className='d-flex justify-content-end align-items-start mt-0 tabla_reporte_50'>
                                    <button className='btn btn-info text-white my-1' 
                                        style={{width: '25%', marginRight: '0px'}} 
                                        onClick={user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR) ? handleFiltrarAll : handleFiltrarPorUsuario}>
                                            Filtrar
                                    </button>
                                </div>
                            )
                        }

                        {/* <div className='d-flex justify-content-between align-items-start mt-0 tabla_reporte_50'> */}                        
                    </div>                  
                </div>
            </div>

            <div className='row' style={{margin: 0}}>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-center' >                       
                        <div className='d-flex justify-content-between align-items-center mt-0 tabla_reporte_50'>
                            <p align='left' className='mt-0' >Nombre: <span className='text-black-50'> { deudas == null ? " " : deudas.NombreCliente }</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mx-2'>
                <div className='col'>
                    {
                        loadingListaReporteFiltrado ? (
                            <div className='d-flex justify-content-center align-items-center mb-2' >
                                <div className="d-flex justify-content-center align-items-center tabla_reporte_50">
                                    <div className="loader2"></div>
                                </div> 
                            </div> 
                        ) : (
                            <>
                                <div className='d-flex justify-content-center align-items-center mb-2' >
                                    <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll tabla_reporte_50'> 
                                    {
                                        user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR)? (
                                            <Suspense fallback={<h4>Cargando...</h4>}>
                                                <TablaReporteHistorico headers={headersAdmin} dataBody={listaReporteFiltrado}/>                                                 
                                            </Suspense>
                                            
                                        ) : (
                                            <Suspense fallback={<h4>Cargando...</h4>}>
                                                <TablaReporteHistorico headers={headersCliente} dataBody={listaReporteFiltrado}/>                                                 
                                            </Suspense>
                                        )
                                    }                                                    
                                    </div>
                                </div>
                            </>                                                               
                        )
                    } 
                    
                </div>
            </div>

            <div style={{ display: 'none'}}>
                {
                    user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK || user.role == UserRole.CONTADOR) ? (
                        <TablaReporteHistoricoExcel headers={headersAdmin} dataBody={listaReporteFiltrado}/>                                             
                    ) : undefined
                }
            </div>


            {/* <div className='row mx-2'>
                <div className='col'>
                    <div className='d-flex justify-content-center align-items-center mb-2' >
                        <div className='d-flex justify-content-between align-items-center mt-0 tabla_scroll tabla_reporte_50'> 
                            {
                                loadingListaReporteFiltrado ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="loader2"></div>
                                    </div> 
                                ) : (
                                    user && (user.role == UserRole.ADMIN || user.role == UserRole.BANK)? (
                                        <Suspense fallback={<h4>Cargando...</h4>}>
                                            <TablaReporteHistorico headers={headersAdmin} dataBody={listaReporteFiltrado}/> 
                                        </Suspense>
                                    ) : (
                                        <Suspense fallback={<h4>Cargando...</h4>}>
                                            <TablaReporteHistorico headers={headersCliente} dataBody={listaReporteFiltrado}/> 
                                        </Suspense>
                                    )                                    
                                )
                            }                            
                        </div>
                    </div>
                </div>
            </div> */}

            <Toaster
                position="top-right"
                toastOptions={{
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                }}
            />
        </>
    )
}