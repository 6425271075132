import React, { useState } from 'react';

const MyAlertComponent = ({mensaje}) => {
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>QR No Pagado!</strong> {mensaje}.
          <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
        </div>
      )}
    </>
  );
};

export default MyAlertComponent;
