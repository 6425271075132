import React, { useState, useEffect } from 'react'
import { useDeudas } from './../context/DeudasContext'
import { MostrarDeudas } from '../components/MostrarDeudas';

export const Deuda = () => {

  const [misDeudas, setMisDeudas] = useState([]);
  const [seleccion, setSeleccion] = useState(null); 
  const [currentPageSelected, setCurrentPageSelected] = useState(false);

  const { deudas, deudasCargadasAlArray, setDeudasCargadasAlArray } = useDeudas();

  const onChangeSelect = (event) => {

    const { value } = event.target
    if (value !== 'predeterminado'){
      setSeleccion(value) 
      setMisDeudas(deudas.DetalleLotes.find( ({nombreLote}) =>  nombreLote === value ).DetalleDeuda)
    }else{
      setMisDeudas([]);
      setSeleccion(null) 
    }
    

    /* const opcionSeleccionada = opciones.find(
      (opcion) => opcion.id.toString() === evento.target.value
    ); */

    // setMisDeudas(deudas.DetalleLotes.find( ({nombreLote}) =>  nombreLote == value ).DetalleDeuda)
  }

  useEffect( () => {
    setDeudasCargadasAlArray(false)
  }, [deudasCargadasAlArray]);

  return (
    <>
      <div className='row mx-2'>
        <div className='col'>
          <div className='d-flex justify-content-center align-items-center'>
            <p align='left' className='mt-4' style={{ width: '35rem'}}>Nombre: <span className='text-black-50'> { deudas == null ? " " : deudas.NombreCliente }</span></p>
          </div>
        </div>
      </div>   

      <div className='row mx-2'>
        <div className='col'>
          <div className='d-flex justify-content-center align-items-center'>
            <select className="form-select" id="tipo" aria-label="Floating label select example" style={{ width: '35rem'}} onChange={onChangeSelect} disabled={currentPageSelected}>
                <option value={'predeterminado'} defaultValue>Seleccionar un lote</option>
                {/* <option value="" disabled >Seleccionar un lote</option> */}
                {
                  deudas == null ? " " : (deudas.DetalleLotes.map( (opcion, indice) => (
                    <option key={indice} value={opcion.nombreLote}> {opcion.nombreLote}</option>
                  )))
                }
            </select>
          </div>
        </div>
      </div>

      <div className='row mx-2'>
        <div className='col'>
          {/* <div className='d-flex justify-content-center align-items-center mt-4 mb-2'> */}
            {
              misDeudas.length === 0 ? 
              undefined :
              (
                <div className='d-flex justify-content-center align-items-center mt-4 mb-2'>
                  <>
                    <div className='d-flex justify-content-between align-items-center mt-1' style={{width: '35rem'}}>
                      <p align='left' > Tu selección ha sido el lote: <span style={{ fontWeight: 'bold'}}> {seleccion} </span> </p>                  
                    </div>                    
                  </>
                </div>
              )
            }          
          {/* </div> */}
        </div>
      </div>

      <div className='row mx-2'>
        <div className='col'>
          {
            misDeudas.length === 0 ? 
            (
              <div className='d-flex justify-content-center align-items-center mt-4 mb-2'>
                <div className='d-flex justify-content-between align-items-center mt-1' style={{width: '35rem'}}>
                <p className='mt-2'>Debes Seleccionar un lote</p>                  
                </div>              
              </div>
            )
            :
            (
              <MostrarDeudas
                misDeudas={misDeudas}
                seleccion={seleccion}
                codigo={deudas.DatosFiscales.FacturaNITCI}
                setCurrentPageSelected={setCurrentPageSelected}
                nombreCliente={deudas.NombreCliente}
                factNombre={deudas.DatosFiscales.FacturaNombre}
              />
            )
          }
        </div>
      </div>
      
    </>
  )
}