import React from 'react'
import { PrintToPDF } from './PrintToPDF'

export const PageStatic = () => {
  return (
    <>
        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center'>
                    <p align='left' className='mt-4' style={{ width: '35rem'}}>Nombre: <span className='text-black-50'> Reinaldo Jimenez</span></p>
                </div>
            </div>
        </div>


        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center'>
                    <select className="form-select" id="tipo" aria-label="Floating label select example" style={{ width: '35rem'}}>
                        <option value={'predeterminado'} defaultValue>Seleccionar un lote</option>
                        <option value={'lote1'} defaultValue>Lote 1</option>
                        <option value={'lote2'} defaultValue>Lote 2</option>                        
                    </select>
                </div>
            </div>
        </div>


        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center mt-4 mb-2'>
                    <>
                        <div className='d-flex justify-content-between align-items-center mt-1' style={{width: '35rem'}}>
                            <p align='left' > Tu selección ha sido el lote: <span style={{ fontWeight: 'bold'}}> Lote X </span> </p>                  
                        </div>                       
                    </>
                </div>
            </div>
        </div>


        <div className='row mx-2'>
            <div className='col'>
                <>
                    <div className='d-flex justify-content-center align-items-center mb-2'> 
                        <div className='d-flex justify-content-between align-items-center mt-0' style={{width: '35rem'}}> 
                            <button className='btn btn-info text-white my-1'>Generar QR</button>                    
                            
                            <div className='d-flex justify-content-center align-items-center'>
                            <div className="d-flex justify-content-center">
                                    <i className="loader2"></i> <span>Generando QR ...</span>
                                </div>                        
                            </div>
                            <button className='btn btn-info text-white my-1'>Mostrar Mis Deudas</button>                            
                        </div>
                    </div>
            
                     
                </>
            </div>
        </div>

        <div className='row mx-2'>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center mb-2'>
                        <div className='d-flex justify-content-between align-items-center mt-0' style={{width: '35rem', overflow: 'auto'}}> 
                            <table className='table table-bordered p-2' style={{width: '100%'}}>
                                <thead>
                                    <tr>
                                    <th>Item</th>
                                    <th>Descripción</th>
                                    <th>Cuota</th>
                                    <th>Multa</th>
                                    <th>Total</th>
                                    <th>Pagar</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr key={1}>
                                        <td data-titulo="Item"> 1 </td>
                                            <td data-titulo="Descripcion"> 
                                                cuota 1 
                                            </td>
                                            <td data-titulo="Cuota"> 
                                                detalle 
                                            </td>
                                            <td data-titulo="Multa">                                             
                                                0                                            
                                            </td>
                                            <td data-titulo="Total"> 
                                                2500
                                            </td>
                                            <td data-titulo="Pagar"> 
                                                <input type='checkbox' className='checkBoxSelect'
                                                    id={`lote-1`} 
                                                    disabled={false}
                                                    value={`lote-1`} 
                                                />
                                            </td> 
                                    </tr>                             
                                </tbody>                
                            </table>        
                        </div>
                </div>
            </div>
        </div>
        
        <div>
            <PrintToPDF/>
        </div>
    </>
  )
}
