import React from 'react'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const formatoFechaHora = (fechaHora) => {
    let fecha_hora = dayjs(fechaHora).utc().format('DD/MM/YYYY HH:mm:ss')
    //let fecha = dayjs(fechaHora).utc().format('DD/MM/YYYY');
    //let hora = dayjs(fechaHora).subtract(ZONA_HORARIA_UTC_4, 'hours').utc().format('HH:mm:ss');

    return `${fecha_hora}`
    //return `${dayjs(fechaHora).utc().format('DD/MM/YYYY')} ${hora}`
    //return `${dayjs(fechaHora).utc().format('DD/MM/YYYY')} ${dayjs(hora).utc().format('HH:mm:ss')}`
}

const TablaSincronizarSAP = ( {headers, dataBody, handleChangeCheckBox} ) => {

    // const handleChangeCheckBox = (event) => { }
  return (
    <table className='table table-bordered'>
        <thead>
            <tr>
                {
                    headers.map((columna, indice) => (
                        <th key={indice}> {columna} </th>
                    ))
                }
            </tr>
        </thead>

        <tbody>
            {
                dataBody.map((detalle, indice) => (
                    <tr key={indice}>

                        <td>
                            <input type='checkbox' className='checkBoxSelect'
                                  id={`${detalle.nroTransaccion}`}
                                  disabled={false}
                                  value={`${detalle.nroTransaccion}`}
                                  name={`${detalle.nroTransaccion}`}
                                  onChange={handleChangeCheckBox}/>
                        </td> 
                        <td> {detalle.nroDocumentoSAPFactura} </td>                       
                        <td> {detalle.nroFactura} </td>                       
                        <td> { detalle.nombreCliente}</td>                        
                        <td> {detalle.nombreFactura} </td>
                        <td> {detalle.nitFactura}</td>
                        <td> {detalle.fecha} </td>
                        <td> {detalle.montoMulta} </td>                                               
                    </tr>
                ))
            }
        </tbody> 
    </table>
  )
}

export default TablaSincronizarSAP